<template>
  <div>
    <div class="main-content" v-if="getAdminType() !== 'user'">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="flex-row-reverse input-group input-group-flush d-flex">
                <input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
                <span class="border-0 input-group-text">
                  <i class="fe fe-search"></i>
                </span>
              </div>
              <div class="ml-4 d-flex align-items-center ">
                <input type="radio" id="active" value="1" v-model="status" class='ml-2' />
                <label for="active" class='mb-0 ml-2'>Active</label>

                <input type="radio" id="inactive" value="0" v-model="status" class='ml-5' />
                <label for="inactive" class='mb-0 ml-2'>Inactive</label>
              </div>

            </div>
            <b-table striped hover selectable responsive :items="admins" :fields="fields" :busy="fetchingAdmins" @row-clicked="viewStaff">
              <template #table-busy>
                <div class="my-2 text-center text-secondary">
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(staff)="data">
                <div class="flex items-center">
                  <user-avatar :user="data.item" size="sm"></user-avatar>
                  <p class="m-0 ml-3">
                    {{ `${data.item.fname} ${data.item.lname}` }}
                  </p>
                </div>
              </template>
              <template #cell(role)="data">
                <span>{{
                  data.item.role.split('_').join(' ') | titleCase
                }}</span>
              </template>
              <template #cell(active)="data">
                <span :class="[data.item.active == 1 ? 'text-green-500' : 'text-red-500']">{{
                  data.item.active == 1 ? 'Active' : 'Inactive'
                }}</span>
              </template>
              <template #cell(created_at)="data">
                <span>{{ data.item.created_at | date('MMMM d, yyyy') }}</span>
              </template>
              <template #cell(ab_testing_role)="data">
                <span>{{ data.item.ab_testing_role == null ? '-' : data.item.ab_testing_role }}</span>
              </template>
            </b-table>

            <div class="card-footer" v-if="total">
              <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"></b-pagination>
            </div>
            <div v-if='!fetchingAdmins && admins.length === 0' class='p-5 text-xl text-center'>
              No user Found
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content card-body d-flex flex-column justify-content-center align-items-center" v-else>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
      </div>
      <p class="mt-3 text-muted">Permission not allowed!</p>
      <button class="px-6 py-2 btn-primary btn-sm" @click="$router.go(-1)">
        Go Back
      </button>
    </div>
  </div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import { getAdminType } from '@/composables/core/permissions/admin'
import UserAvatar from '@/components/core/UserAvatar.vue'
import { ref, computed, watch } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import { useRouter } from 'vue-router/composables'
import { useRoute } from 'vue-router/composables'
import { appendObjectToCurrentURL } from '@/composables/core/routing'


const fields = [
  { key: 'staff', label: 'Staff' },
  { key: 'phone', label: 'Phone' },
  { key: 'email', label: 'Email' },
  { key: 'active', label: 'Status' },
  { key: 'role', label: 'Role' },
  { key: 'created_at', label: 'Created At' },
  { key: 'ab_testing_role', label: 'AB Testing role' },
]
const perPage = ref(10)
const currentPage = ref(1)
const total = ref(null)
const status = ref(1)
const admins = ref([])
const fetchingAdmins = ref(true)
const search = ref('')
const route = useRoute()
watch([currentPage, search, status], (val) =>
{
  appendObjectToCurrentURL('page', val[0])
  appendObjectToCurrentURL('q', val[1])
  appendObjectToCurrentURL('status', val[2])
  fetchStaff()
})

const filteredStaff = computed(() =>
{
  const searchValue = search.value.toLowerCase()
  const statusValue = status.value
  // if (!searchValue) return admins.value
  return admins.value.filter((staff) =>
  {
    return (
      staff.fname.toLowerCase().includes(searchValue) ||
      staff.lname.toLowerCase().includes(searchValue) ||
      staff.email.toLowerCase().includes(searchValue) ||
      staff.phone.toLowerCase().includes(searchValue)
    )
  }).filter((staff) =>
  {
    return statusValue == '1' ? staff.active == '1' : staff.active == '0'
  })
})

const convertURLParamsToObject = () =>
{
  const urlParams = route.query
  currentPage.value = urlParams.page ? parseInt(urlParams.page) : 1
  search.value = urlParams.q ? urlParams.q : ''
  status.value = urlParams.status ? parseInt(urlParams.status) : 1
  fetchStaff()
}

const router = useRouter()

const viewStaff = (staff) =>  
{
  if (getAdminType() !== 'super_admin' && staff.role === 'super_admin') {
    return
  }
  router.push({ name: 'ShowStaff', params: { staffId: staff.id } })
}

const fetchStaff = () =>
{
  fetchingAdmins.value = true
  axiosInstance
    .get(`/v1/staff?metadata=true&limit=${perPage.value}&page=${currentPage.value}&search=${search.value}&status=${status.value}`)
    .then((res) =>
    {
      admins.value = res.data.data.length ? res.data.data : []
      total.value = res.data?.metadata?.total || null
    })
    .finally(() => (fetchingAdmins.value = false))
}
convertURLParamsToObject()
</script>

<style lang="css" scoped>
.VueTables__search {
  padding-left: 10px !important;
}
</style>
